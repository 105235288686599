<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:45
-->
<template>
	<div class="acDetail">
		<common-title :title="'共建基地详情'" />
		<div class="acDetail-content">
			<div class="common-table">
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">活动ID</span>
						<el-input readonly="readonly" v-model="acDetail.id" placeholder="请输入"
							class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">共建基地名称</span>
						<el-input readonly="readonly" v-model="acDetail.comBaseName" placeholder="请输入"
							class="common-table-val" />
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">活动名称</span>
						<el-input readonly="readonly" v-model="acDetail.activityName" placeholder="请输入"
							class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">组织方</span>
						<el-input readonly="readonly" v-model="acDetail.organizer" placeholder="请输入"
							class="common-table-val" />
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">活动时间</span>
						<span
							class="common-table-text">{{dateFormat(acDetail.starTime)}} 至 {{dateFormat(acDetail.endTime)}}</span>
					</div>
					<div class="common-table-col">
						<span class="common-table-label">活动地址</span>
						<span class="common-table-text">{{acDetail.address}}</span>
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">活动详情</span>
						<quill-editor :options="editorOption" class="common-table-val" :style="{'height': '400px'}"
							v-model="acDetail.activityDetails" @focus="editorFocus($event)">
						</quill-editor>
						<!-- <el-input type="textarea" v-model="acDetail.plan" placeholder="请输入" class="common-table-val" /> -->
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">工时设定</span>
						<div style="border-top: 0;" class="common-row-time">
							<div class="row-time-val">
								<div class="time-val-item">
									<span class="val-item-label">核定活动人数上限</span>
									<el-input :readonly="!enableEdit" v-model="acDetail.maxNumPeople"
										class="val-item-ipt" placeholder="请输入" />
								</div>
								<div class="time-val-item">
									<span class="val-item-label">核定单人工时数上限</span>
									<el-input :readonly="!enableEdit" v-model="acDetail.maxOneWorkingHours"
										class="val-item-ipt" placeholder="请输入" />
								</div>
								<div class="time-val-item">
									<span class="val-item-label">核定活动总工时数上限</span>
									<el-input :readonly="!enableEdit" v-model="acDetail.maxWorkingHours"
										class="val-item-ipt" placeholder="请输入" />
								</div>
							</div>
							<div class="row-time-mark">
								<span class="time-mark-label">注：</span>
								<div class="time-mark-content">
									<span>核定活动人数上限指该活动的可参加的总人数的上限。（限整数）</span>
									<span>核定核定单人工时数上限指该活动单人可获得的工时数上限。（限整数/一位小数）</span>
									<span>核定活动总工时数上限指该活动的最终生成工时表的所有志愿者工时数之和。（限整数/一位小数）</span>
								</div>
							</div>
							<!-- <el-button v-if="!enableEdit" @click="setManhour('edit')" class="row-time-btn"
								type="primary">修改</el-button> -->
							<div class="" style="display: flex;align-items: center;">
								<el-button v-if="enableEdit" @click="setManhour('cancel')" class="row-time-btn"
									type="cancel">取消</el-button>
								<el-button v-if="enableEdit" @click="setManhour('confirm')" class="row-time-btn"
									type="primary">确定</el-button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 工时表 -->
			<div class="">
				<div class="activityDetail-title">
					<span>工时表</span>
					<el-button v-if="acDetail.workingHoursList&&acDetail.workingHoursList.length > 0"
							@click="downLoadTimeSheet">导出工时表</el-button>
					</div>
					<div v-if="acDetail.workingHoursList&&acDetail.workingHoursList.length > 0"
						class="activityDetail-table">
						<el-table style="width: 100%" :data="acDetail.workingHoursList" border>
							<el-table-column prop="regNum" label="注册号">
							</el-table-column>
							<el-table-column prop="studentId" label="学号">
							</el-table-column>
							<el-table-column prop="name" label="姓名">
							</el-table-column>
							<el-table-column prop="gender" label="性别" width="60px">
								<template slot-scope="scope">
									<span>{{scope.row.gender == true?"男":"女"}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="collegeName" label="学院班级">
								<template slot-scope="scope">
									<span>{{scope.row.collegeName}}{{scope.row.faculty}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="workingHours" label="工时数" width="100px">
							</el-table-column>
						</el-table>
					</div>
					<div v-else class="activityDetail-upload">
						<div v-if="acDetail.activityStatus != 0" class="activityDetail-upload-top">
							<span @click="manhourFile('upload')" class="upload-top-btn">选择文件</span>
							<span class="upload-top-text">工时表文件.xlsx</span>
						</div>
						<div v-if="acDetail.activityStatus != 0" @click="manhourFile('download')"
							class="activityDetail-upload-bottom">
							<img src="@/assets/images/common/xls.png" alt="">
							<span>点击下载工时表模板</span>
						</div>
						<div v-if="upLoadErrorMsg" class="activityDetail-upload-fail">
							<div class="upload-fail-top">
								<span style="color: #DF4635;">上传失败！</span>
								<span>①确认工时表模板是否正确，可在上传表格处下载。</span>
								<span>②工时表F列无任何错误提示显示。</span>
								<span>③确认是否使用《Microsoft Office Excel 2007》及以上版本制作，WPS office和盗版均有可能导致失败。</span>
								<span>④如始终无法上传，可通过活动招募详情导入志愿者工时。（进入活动招募详情，点击“生成工时表”，关联此活动，选择志愿者并输入工时）。</span>
							</div>
							<div class="upload-fail-bottom">
								<span>错误信息：</span>
								<span>{{upLoadErrorMsg}}</span>
							</div>
						</div>
					</div>
				</div>
				<el-upload class="uploadImage" style="display:none;" :action="upLoadUrl" :show-file-list="false"
			:before-upload="beforeUpload" :http-request="handleCustomRequest"></el-upload>
			<!-- </div> -->
			<!-- <div class="">
				<div class="acDetail-title">
					工时表
				</div>
				<el-table :data="acDetail.workingHoursList" border>
					<el-table-column prop="regNum" label="注册号">
					</el-table-column>
					<el-table-column prop="studentId" label="学号">
					</el-table-column>
					<el-table-column prop="name" label="姓名">
					</el-table-column>
					<el-table-column prop="gender" label="性别">
						<template slot-scope="scope">
							<span>{{scope.row.gender == true?"男":"女"}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="collegeName" label="学院班级">
						<template slot-scope="scope">
							<span>{{scope.row.collegeName}}{{scope.row.faculty}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="workingHours" label="工时数">
					</el-table-column>
				</el-table>
			</div> -->
		</div>
	</div>
</template>

<script>
	import {
		getActivityDetail,
		editActivity,
		uploadManhourFile,
		exportActivityTimeSheet
	} from "@/api/activity";
	export default {
		data() {
			return {
				acDetail: {},
				activityId: "",
				enableEdit: false,
				editorOption: {
					placeholder: "暂无内容......",
				},
				upLoadErrorMsg: undefined,
				upLoadUrl: "",
			};
		},
		mounted() {
			this.activityId = this.$route.query.activityId
			this.activityDetailFn()
		},
		methods: {
			dateFormat(intDate) {
				if (intDate) {
					return intDate.replace(" 00:00:00", "")
				}
			},
			editorFocus(e) {
				e.enable(false);
			},
			workingHoursCheck(type, intNum) {
				if(intNum) {
					let regx1 = /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/;
					let regx2 = /^[1-9]+[0-9]*$/;
					if (type == 'point') {
						if (regx1.test(intNum)) {
							let pointIndex = intNum.lastIndexOf('.')
							if (pointIndex != -1) {
								let regx3 = /^\d+(\.\d{1,1})?$/
								if (regx3.test(intNum)) {
									let pointNum = intNum.slice(pointIndex + 1, intNum.length)
									console.log("pointNum", pointIndex, pointNum)
									if ((pointNum * 1) == 5) {
										return true
									} else {
										return false
									}
								} else {
									return false
								}
							} else {
								return true
							}
						} else {
							return false
						}
					} else {
						if (regx2.test(intNum)) {
							return true
						} else {
							return false
						}
					}
				} else {
					return false
				}
			},
			setManhour(type) {
				switch (type) {
					case "edit":
						this.enableEdit = true
						break;
					case "cancel":
						this.enableEdit = false
						break;
					case "confirm":
						let activityData = JSON.parse(JSON.stringify(this.acDetail))
						console.log("activityData", activityData)
						if (!this.workingHoursCheck('integer', activityData.maxNumPeople + '')) {
							this.$message.error("核定活动人数上限数值不规范~")
							return
						}
						if (!this.workingHoursCheck('point', activityData.maxOneWorkingHours + '')) {
							this.$message.error("核定单人单日工时数上限~")
							return
						}
						if (!this.workingHoursCheck('point', activityData.maxWorkingHours + '')) {
							this.$message.error("核定活动单日总工时数上限~")
							return
						}
						editActivity(activityData.id, activityData).then((res) => {
							if (res.code == 0) {
								this.$message.success("工时设定修改成功~")
								this.activityDetailFn()
								this.enableEdit = false
							}
						})
						break;
				}
			},
			downLoadTimeSheet() {
				let params = {
					id: this.activityId,
				}
				exportActivityTimeSheet(params).then(res => {
					console.log("downLoadApply", res)
					const blob = new Blob([res], {
						type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
					})
					// const blob = new Blob([res], {type: "application/vnd.ms-excel;charset=utf-8"})
					if ('download' in document.createElement('a')) {
						// 非IE下载
						const elink = document.createElement('a')
						elink.download = "工时表.xlsx"
						elink.style.display = 'none'
						elink.href = URL.createObjectURL(blob)
						document.body.appendChild(elink)
						elink.click()
						URL.revokeObjectURL(elink.href) // 释放URL 对象
						document.body.removeChild(elink)
					} else {
						// IE10+下载
						navigator.msSaveBlob(blob, "工时表.xlsx")
					}
				})
			},
			// 获取活动详情
			activityDetailFn() {
				getActivityDetail(this.activityId, {
					id: this.activityId
				}).then((res) => {
					if (res.code == 0) {
						this.acDetail = res.data
					}
				})
			},
			manhourFile(type) {
				switch (type) {
					case "upload":
						document.querySelector(".uploadImage input").click();
						break;
					case "download":
						window.open("https://www.api.volunteer.zjut.edu.cn/upload/upload/file/2303/93e11b37e5904a009bcaaabfd5ee36cf.xlsx")
						break;
				}
			},
			beforeUpload(file) {
				console.log("beforeUpload", file);
				this.loading = true
				let fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
				if (fileType != "xlsx") {
					this.$message({
						message: "上传文件只能是xlsx格式!",
						type: "warning"
					});
					return false;
				}
			},
			handleCustomRequest(data) {
				console.log("handleCustomRequest", data)
				this.fileData = []
				this.upLoadErrorMsg = undefined
				const formData = new FormData();
				formData.append('file', data.file);
				formData.append('activityId', this.activityId);
				uploadManhourFile(formData).then((res) => {
					console.log("上传成功", res)
					if (res.code == 0) {
						this.loading = false
						this.upLoadErrorMsg = undefined
						this.$message({
							message: "工时表上传成功!",
							type: "success"
						});
						// this.$router.go(-1)
						this.activityDetailFn()
					} else {
						this.loading = false
						this.$message({
							message: res.msg,
							type: "error"
						});
						this.upLoadErrorMsg = res.msg
					}
				}).catch((error) => {
					console.log("error", error.response)
					this.loading = false
					this.$message({
						message: error.response.data.msg,
						type: "error"
					});
					this.upLoadErrorMsg = error.response.data.msg
				})
			},
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}

	/deep/.el-textarea__inner {
		height: 400px !important;
		resize: none;
		border: 0;
	}

	.acDetail {
		.acDetail-content {
			padding: 20px 20px;

			.val-item-ipt {
				border: 1px solid #EDF1F7;
			}

			.common-table-col {
				position: relative;

				.common-table-val {
					// overflow: hidden; //超出的文本隐藏
					// text-overflow: ellipsis; //溢出用省略号显示
					// white-space: nowrap; //溢出则不换行
				}

				.common-table-btn {
					width: 60px;
					height: 28px;
					position: absolute;
					top: 4px;
					right: 11px;
				}

				.row-time-btn {
					width: 96px;
					margin-top: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}

		.acDetail-title {
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: bold;
			line-height: 22px;
			color: #1C2233;
			margin: 20px 0 10px 0;
		}
	}
	.activityDetail-title {
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: bold;
		line-height: 22px;
		color: #1C2233;
		margin: 20px 0 10px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.activityDetail-title-btn {}
	}
	.activityDetail-upload {
		display: flex;
		flex-direction: column;

		.activityDetail-upload-top {
			display: flex;
			align-items: center;

			.upload-top-btn {
				width: 80px;
				height: 28px;
				background: #F6F7F9;
				border: 1px solid #DCDFE6;
				border-radius: 2px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #909399;
				margin-right: 8px;
				cursor: pointer;
			}

			.upload-top-text {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 20px;
				color: #1C2233;
			}
		}

		.activityDetail-upload-bottom {
			display: flex;
			align-items: center;
			cursor: pointer;
			margin-top: 10px;

			img {
				width: 16px;
				height: 16px;
				margin-right: 8px;
			}

			span {
				font-size: 12px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 17px;
				color: #606266;
			}
		}
	}
	.activityDetail-upload-fail {
		display: flex;
		flex-direction: column;

		.upload-fail-top,
		.upload-fail-bottom {
			display: flex;
			flex-direction: column;
			margin-top: 16px;

			span {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 20px;
				color: #303133;
			}
		}
	}
</style>
